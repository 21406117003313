<template>
  <div>
    <div v-if="selectedLabel">
      <!-- <div style="margin:20px auto;text-align: center;">
      <img src="/image/logo.gif" width="45%" />
    </div> -->
      <cube-tab-bar v-model="selectedLabel" show-slider inline class="headerNav" @change="changeTab">
        <cube-tab v-for="item in tabs" :label="item.label" :key="item.label" style="color:#ff9;flex-wrap: nowrap;">
          <div style="font-weight: bold;">{{ item.label }}</div>
        </cube-tab>
      </cube-tab-bar>
      <div v-if="selectedLabel === 'ABOUT US'">
        <Aboutus ref="aboutus"></Aboutus>
      </div>
      <div v-else-if="selectedLabel === 'PRODUCT'">
        <Product ref="product"></Product>
      </div>
      <div v-else>
        <Fair ref="fair"></Fair>
      </div>
    </div>
    <div v-else-if="model">
      <div class="productDetailContainer">
        <div class="productDetailHeader">
          <div class="productDetailHeaderModel">{{ model }}</div>
          <div class="productDetailHeaderClose"></div>
        </div>
        <div class="productDetail">
          <div>
            <ProductDetail :model.sync="model" style="padding-bottom:100px;" v-on:changeModel="changeModel"></ProductDetail>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Fair from '@/templates/fair/index'
import Product from '@/templates/product/index'
import ProductDetail from '@/templates/product/detail'
import Aboutus from '@/templates/aboutus/index'
export default {
  name: "Index",
  components: {
    Fair, Product, Aboutus, ProductDetail
  },
  computed: {},
  data () {
    return {
      toast: null,
      selectedLabel: '',
      tabs: [],
      model: ''
    };
  },
  async mounted () {
    this.toast = this.$createToast({
      txt: 'Loading...',
      mask: true
    })
    this.toast.show()
    await this.init()
    this.toast.hide()
  },
  methods: {
    async init () {
      if (this.$route.query && this.$route.query.qrParam) {
        const result = await this.$http.post('/mobile/index/index', { op: 'qrSearch', k: this.$route.query.qrParam })
        this.model = result.data.model
      }
      if (!this.model) {
        const result = await this.$http.post('/mobile/index/index', { op: 'init' })
        this.tabs = result.data.menus
        this.selectedLabel = result.data.menuKey
      }
    },

    changeTab (e) {
      this.selectedLabel = e
    },

    changeModel (e) {
      this.model = e
    }
  },
};
</script>

<style scoped>
.headerNav {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  background: #115090;
  margin: 0 auto !important;
  margin-bottom: 0 !important;
  padding: 10px 0 !important;
}


.productDetailContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.productDetailHeader {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  font-size: 18px;
}

.productDetailHeaderModel {
  text-align: left;
  padding-left: 20px;
  font-weight: bold;
}

.productDetailHeaderClose {
  text-align: right;
  flex: 1;
  padding-right: 20px;
  font-size: 20px;
}

.productDetail {
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: auto;
  max-height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
</style>
